import React, { useEffect, useState } from 'react';
import web_logo from "../assets/vittiLogo.png"
import humbergerIcon from "../assets/humburger.png";
import nextIcon from "../assets/right-angle.svg"
import cancelIcon from "../assets/close.png"
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
const Navbar = () => {

  const [scrolling, setScrolling] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const navigateMenu = (location) => {
    switch (location) {
      case "/":
        navigate("/");
        setIsMenuOpen(false);
        break;
      case "about-us":
        navigate("/about-us");
        setIsMenuOpen(false);
        break;
      case "services":
        navigate("/services");
        setIsMenuOpen(false);
        break;
      case "blog":
        navigate("/blog");
        setIsMenuOpen(false);
        break;
      case "career":
        navigate("/career");
        setIsMenuOpen(false);
        break;
      case "contact-us":
        navigate("/contact-us");
        setIsMenuOpen(false);
        break;
      default:
        navigate("/")

    }

  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`${scrolling ? 'bg-[#fff]' : 'bg-[#fff]'} fixed top-[-1] z-50 transition-all duration-300 ease-in-out w-[100%] md:px-[4%] xs:px-[4%] flex item-center justify-center  h-[84px] ${scrolling ? 'shadow-md' : ''}`}>
        <div className='w-[90%] md:w-[100%] lg:w-[90%] h-[100%] flex items-center justify-between'>

          {/* Web logo  */}
          <div className='lg:w-[10%] md:w-[8%] p-[0.1rem] lg:h-[90%] xs:h-[100%]' onClick={() => navigate("/")}>
            <div className='lg:w-[100%] lg:h-[100%] md:h-[100%] xs:h-[100%] xs:w-[100%] lg:flex items-center justify-center'><img src={web_logo} alt="" className='h-[100%] object-cover lg:w-[60%] md:w-[100%] xs:w-[100%] cursor-pointer' /></div>

          </div>

          {/* Menu Item  for large screen*/}
          <div className='hidden md:w-[80%] lg:w-[54%] h-[100%] md:flex lg:flex items-center justify-between cursor-pointer'>
            <div>
              <a href="/" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>Home</a>
            </div>
            <div>
              <a href="/about-us" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>About Us</a>
            </div>
            <div>
              <a href="/services" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>Services</a>
            </div>

            <div>
              <a href="/blog" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>Blog</a>
            </div>
            <div>
              <a href="/career" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>Careers</a>
            </div>
            <div>
              <a href="/contact-us" className={`text-sm font-semibold notoSans text-[#4564aa]  hover:text-[#d18812]`}>Contact Us</a>
            </div>
            <div>
              <button onClick={openModal} className={` h-full bg-[#204093] flex items-center justify-center px-10 py-7 text-lg font-semibold notoSans text-[#fff]  hover:underline`}>Get Quote</button>
            </div>

          </div>


          <Modal isOpen={modalIsOpen} onClose={closeModal} />


          {/* <!-- Hamburger Icon for mobile --> */}

          <div className="block lg:hidden md:hidden flex item-center">
            <button
              onClick={toggleMenu}
              id="menu-toggle"
              className="text-white focus:outline-none"
            >
              {isMenuOpen ? (
                <img
                  className="w-[100%] h-[25px]"
                  src={cancelIcon}
                  alt="Opened Menu"
                />
              ) : (
                <img
                  className="w-[100%] h-[20px]"
                  src={humbergerIcon}
                  alt="Closed Menu"
                />
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile view menu bar items */}
      {/* Blur Effect */}
      <div className="flex w-[100%] items-center justify-between">
        {isMenuOpen && (
          <div
            className={`fixed inset-0 z-40 ${isMenuOpen
              ? "opacity-100 top-[240px] backdrop-filter backdrop-blur-lg pointer-events-auto"
              : "opacity-0 pointer-events-none"
              }`}
            onClick={toggleMenu}
            style={{ backgroundColor: "#204093" }} // Adjust opacity here
          ></div>
        )}
      </div>

      {isMenuOpen && (
        <div className="fixed z-[100] bg-[#204093] w-[100%] mt-[5rem] right-0 h-[22.4vh]">
          <div className=" w-full bg-[#204093] right-0 top-[3.8rem] z-[-1]">
            <div className="w-[100%] bg-[#204093] rounded-[4px] pt-[2%] lg:hidden xs:block overflow-y-auto ">

              <div onClick={() => navigateMenu("/")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8 cursor-pointer border-b border-white">
                <div><p className="text-white">Home</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>

              <div onClick={() => navigateMenu("about-us")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8 cursor-pointer border-b border-white">
                <div><p className="text-white" >About us</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>

              <div onClick={() => navigateMenu("services")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8 cursor-pointer border-b border-white">
                <div ><p className="text-white">Services</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>



              <div onClick={() => navigateMenu("blog")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8 cursor-pointer border-b border-white">
                <div><p className="text-white">Blog</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>

              <div onClick={() => navigateMenu("career")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8 cursor-pointer border-b border-white">
                <div><p className="text-white">Career</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>


              <div onClick={() => navigateMenu("contact-us")} className="w-[100%] h-[35px]  flex items-center justify-between  text-[12px] mx-auto px-8 py-8  cursor-pointer border-b border-white">
                <div><p className="text-white">Contact us</p></div>
                <img src={nextIcon} alt="forword icon" className='h-[25px] w-[25px]' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar
