import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Home.css";

import CollapsibleBox from '../component/CollapsibleBox';
import TypingEffect from '../component/TypingEffect';
import CustomButton from '../component/CustomButton';
import SubHeading from '../component/SubHeading';

import Healthcare from '../assets/services/industies/Healthcare-img.jpg'
import IT from '../assets/services/industies/IT-img.jpg'
import RealEstate from '../assets/services/industies/RealEsate-img.jpg'
import Tour from '../assets/services/industies/Tour-img.jpg'
import University from '../assets/services/industies/University-img.jpg'
import Business from '../assets/services/industies/business-img.jpg'
import YourSuccess from '../assets/YourSuccessimg.png'
import ClientsSection from '../component/ClientSection';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ServiceCard from '../component/ServiceCard'
import seoImg from "../assets/services/seo.jpg";






const sectors = [
  { id: 1, title: "HEALTHCARE", imgUrl: Tour },
  { id: 2, title: "COLLEGES & UNIVERSITIES", imgUrl: University },
  { id: 3, title: "BUSINESSES & ORGANISATIONS", imgUrl: IT },
  { id: 4, title: "IT SERVICES", imgUrl: IT },
  { id: 5, title: "REAL ESTATE", imgUrl: RealEstate },
  { id: 6, title: "TRAVEL & TOURISM", imgUrl: Tour },
  { id: 7, title: "EDUCATIONAL INSTITUTION", imgUrl: Tour },
  { id: 8, title: "E-COMMERCE", imgUrl: Business },
  { id: 9, title: "MANUFACTURING", imgUrl: IT },
  { id: 10, title: "RETAIL", imgUrl: Tour },
  { id: 11, title: "FASHION & BEAUTY", imgUrl: IT },
  { id: 12, title: "INTERIOR DESIGNING", imgUrl: University },
  { id: 13, title: "HOSPITALITY", imgUrl: Tour },
  { id: 14, title: "FINANCE", imgUrl: seoImg },
  { id: 15, title: "NONPROFITS AND CHARITIES", imgUrl: Business },
  { id: 16, title: "FITNESS AND WELLNESS", imgUrl: Tour },
  { id: 17, title: "FOOD AND BEVERAGE", imgUrl: Tour },
  { id: 18, title: "AUTOMOTIVE", imgUrl: RealEstate }
];

const settings = {
  dots: true,
  infinite: true,
  speed: 5000, // Speed of the slide transition
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0, // No pause between slides
  cssEase: "linear",
  pauseOnHover: true, // Pauses when hovered
  beforeChange: (current, next) => {
    if (current === sectors.length - 1 && next === 0) {
      // Pause at the end of a cycle
      setTimeout(() => {
        document.querySelector('.slick-next').click();
      }, 2000); // Pause for 2 seconds
    }
  },
};




const Your_Success = [
  "Tailored Solutions",
  "Proven Expertise",
  "Cutting-Edge Technology",
  "Transparent Communication",
  "Agile Methodology",
  "Quality Assurance",
  "Customer-Centric Focus",
  "Scalability and Flexibility",
  "Continuous Support"
];

const faqs = [
  {
    id: 1,
    question: "What services does VittiPanya offer ?",
    answer: "VittiPanya offers a range of digital services including social media marketing, website development, logo design, meta ads, Google Ads, content marketing, and SEO services."
  },
  {
    id: 2,
    question: "How can I contact VittiPanya for inquiries ?",
    answer: "You can reach us through email at info@vittipanya.com or by phone at +91 6299228862 We're also available for a face-to-face meeting, just let us know a time that suits you."
  },
  {
    id: 3,
    question: "Is VittiPanya suitable for small businesses ?",
    answer: "Absolutely! Our services are designed to cater to businesses of all sizes. We have packages and solutions that can be tailored to fit the needs and budget of small businesses."
  },
  {
    id: 4,
    question: "What is the importance of social media marketing for my business ?",
    answer: "Social media marketing helps your business build a brand presence, engage with your audience, and drive traffic to your website. It's an essential tool for modern digital marketing."
  },
  {
    id: 5,
    question: "How long does it take to see results from SEO services ?",
    answer: "SEO results can vary, but typically you can start seeing improvements within a few months. It's an ongoing process that builds over time for sustainable growth."
  },
  {
    id: 6,
    question: "What makes VittiPanya’s website development unique ?",
    answer: "Our website development focuses on creating user-friendly, visually appealing sites that align with your brand. We prioritise functionality, responsiveness, and a positive user experience."
  },
  {
    id: 7,
    question: "How do you determine the budget for Google Ads and meta ads ?",
    answer: "The budget for advertising is determined based on your business goals, target audience, and the competitiveness of your industry. We work with you to set a budget that aligns with your objectives."
  },
  {
    id: 8,
    question: "Can I customise the services in your packages ?",
    answer: "Absolutely! Our packages are flexible, and we're happy to customise them based on your specific needs and goals."
  },
  {
    id: 9,
    question: "Do you offer any discounts or promotions ?",
    answer: "We occasionally have promotions, and we also offer discounts for bundled services. Feel free to inquire about our current offers when discussing your project."
  },
  {
    id: 10,
    question: "What payment methods do you accept ?",
    answer: "We accept payments through various methods, including bank transfers, credit cards, and other secure online payment platforms. Details will be provided in our official invoices. These FAQs provide a general overview of our services and address common questions. If you have any specific inquiries or need more information, please feel free to reach out to us directly."
  }
]

const textToType = "We craft captivating digital stories, transforming brands into memorable online experiences. With creativity and strategy, we guide your audience through an immersive journey in cyberspace."

const Home = () => {
  
  
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_ID,
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  
  


  return (
    <div className='w-[100%] '>

      {/* header image box */}
      <div className='headerImg w-[100%] h-[90vh] flex items-center justify-center object-cover inset-0 S'>

        {/* <div className="lg:h-[80vh] md:h-[70vh] lg:w-[100%] absolute inset-0 bg-black opacity-50 xs:w-[100%] xs:h-[70vh]"></div> */}
        <div className='w-[80%] lg:h-[60%] xl:h-[35%] z-10 xs:mt-[8rem]'>
          <h1 className='lg:text-5xl xs:text-3xl xs:mb-[15px] text-[#4364ad] font-bold md:w-[88%] font-extrabold font-poppins'>Get Ready For Your Business &<br /> <span className='text-[#1d1d1d]'>Upgrade all aspects</span></h1>
          <div className='lg:w-[15%] xs:w-[30%] h-[10%] lg:mt-[0.7rem] mt-[0.5rem] xs:mt-[0rem] flex items-center'>
            <div className='h-[7px] w-[100%] bg-[#4364ad] xs:mb-[15px] rounded-md'></div>
          </div>
          <div className='lg:h-[40%] lg:w-[50%] md:h-[48%] md:w-[88%] lg:mt-[0.7rem] mt-[0.5rem] xs:mt-[0rem]'>
            <p className='lg:text-xl xs:text-md md:tracking-wide md:leading-8 text-white font-notoSans font-medium'>
              <TypingEffect text={textToType} color="#1d1d1d" />
            </p>
          </div>
          <div className='mt-5 lg:mt-0'>
            <CustomButton text="Learn More" to="/about-us" className="text-white bg-[#4364ad] hover:bg-[#1d1d1d] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 w-[150px] text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-poppins w-[120px] lg:w-[140px] md:w-[125px] " />
            <CustomButton text="Get Quote" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-[#4364ad] focus:outline-none bg-white rounded-full border border-[#4364ad] hover:bg-[#1d1d1d] w-[150px] hover:text-[#fff] focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 font-poppins w-[120px] lg:w-[140px] md:w-[125px]" />
          </div>
        </div>
      </div>

    {/* Services*/}
    <ServiceCard/>
    {/* Client*/}
 

    <div className=" bg-[#f8f8f8] w-[100%] lg:h-max-content xs:h-max-content lg:p-[5rem] xs:p-[2rem] flex items-center justify-center">
    <ClientsSection/>
      </div>

 {/* sectors in which we work */}
<div className="carousel-container p-10 bg-white">
  <div className="text-center mb-10">
    <h2 className="text-4xl font-semibold text-[#234190]">Industries We Serve</h2>
    <p className="text-md font-medium text-gray-600 mt-4">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
    </p>
    <div className="flex justify-center mt-6 space-x-4">
      <a href="#" className="inline-block px-6 py-2 bg-[#234190] text-white text-medium rounded-lg hover:bg-[#234190]">
        Get Started
      </a>
      <a href="#" className="inline-block px-6 py-2 border border-[#234190] text-[#234190] text-medium rounded-lg hover:bg-[#234190] hover:text-white">
        Learn More
      </a>
    </div>
  </div>
  <Slider {...settings}>
    {sectors.map(sector => (
      <div key={sector.id} className="carousel-item mb-5 flex flex-col items-center">
        <img src={sector.imgUrl} alt={sector.title} className="h-40 w-full object-cover rounded-lg" />
        <p className="text-center text-xl font-semibold mt-3">{sector.title}</p>
      </div>
    ))}
  </Slider>
</div>




      {/* query form */}
      <div class="flex">

        <div className="w-full lg:py-[4rem] lg:px-[2rem] my-8 mx-10">
          <form ref={form} onSubmit={sendEmail}>
            <div className="mb-4">
              <h2 className='text-2xl '>CONTACT US</h2>
              <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="fullName">
                Full Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fullName"
                type="text"
                placeholder="Enter your Full Name"
                name="user_name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="emailId">
                Email Id
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="emailId"
                type="email"
                placeholder="Enter your Email Id"
                name="user_email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phoneNumber"
                type="text"
                placeholder="Enter your Phone Number"
                name="user_phone"
              />
            </div>
            <div className="mb-4">
              <label for="Services" className="block text-[#1d1d1d] text-sm mb-2">Select Services</label>
              <select id="Services" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Services</option>
                <option value="Seo">SEO</option>
                <option value="Social Media Markrting Services">Social Media Markrting Services</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Website Design">Website Design</option>
                <option value="Logo Design">Logo Design</option>
                <option value="Google Ads">Google Ads</option>
              </select>
            </div>
            <div className="flex items-center justify-between mt-5">
              <CustomButton
                type="submit"
                text="Submit"
                className="text-white bg-[#4364ad] hover:bg-[#1d1d1d] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 w-[150px] text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-poppins"
              />
            </div>
          </form>
        </div>
        <div className="w-full enquireBg h-[auto] lg:w-[100%] xs:w-[0%]">
        </div>
      </div>

      <div className='w-[100%] lg:h-[100%] xs:h-max-content px-8 py-8 mt-0 bg-[#204093] flex items-center justify-center'>
        <div className='w-[100%] h-[100%]'>
          <SubHeading text="Your Success, Our Priority: Why Opt for Our Exceptional Services" className="text-center text-xl lg:text-3xl md:text-2xl font-semibold text-[#fff]" />
          <p className='lg:text-center text-[#fff] text-md font-normal	lg:w-[80%] lg:mx-auto font-medium mt-[1rem]'>Empower your journey with us – a steadfast partner dedicated to your success. Our commitment to innovation, personalized service, and unwavering reliability sets us apart, making your choice in us a testament to a future filled with excellence.</p>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="p-4">
              <img src={YourSuccess} alt="" />
            </div>
            <div class="p-4">
              <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                {Your_Success.map((item, index) => (
                  <div key={index} className='max-w-xs rounded overflow-hidden shadow-lg bg-[#fff] h-[100px] w-[100%] text-[14px] text-left'>
                    <div className='px-6 py-4'>
                      <div className='text-small text-[#000] mb-2'>{item}</div>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* FAQ Section */}
      <div className='h-max-content w-[100%] mt-[2rem] mb-[1rem] flex items-center justify-center'>
        <div className='h-[98%] lg:w-[83%] xs:w-[90%]'>
          <p className='text-center text-4xl font-poppinsItalic font-bold'>FAQs</p>
          {
            faqs?.map((item) => {
              return (<CollapsibleBox key={item.id} data={item} />)
            })
          }
        </div>
      </div>


    </div >
  )
}

export default Home
