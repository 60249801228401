import React from "react";
import facebookIcon from "../assets/facebook.png";
import twitterIcon from "../assets/twitter.jpg";
import instaIcon from "../assets/instagram.png";
import linkedinIcon from "../assets/linkdin.jpg";
import vittiLogo from "../assets/vittiLogo.png";
import "./Footer.css"; // Import your custom CSS for footer styling

const Footer = () => {
  return (
    <>
      <footer className="bg-[#234190] text-white px-[9rem] py-8">
        <div className="container mx-auto grid grid-cols-2 md:grid-cols-5 gap-8">
          {/** Brand Section */}
          <div className="footer-section brand-section">
            <img src={vittiLogo} alt="Vittipanya.com" className="footer-logo" />
            <p className="footer-description">
              VittiPanya is a fast-growing digital service provider,
              specializing in bridging the online gap for educational
              institutions, healthcare professionals, and businesses.
            </p>
          </div>

          {/** Service Section */}
          <div className="footer-section">
            <h3 className="text-lg font-semibold mb-4 border-b border-muted pb-2">
              Service
            </h3>
            <ul className="space-y-2">
              {[
                "Social Media Management",
                "Content Creation",
                "Website Design",
                "Logo Design",
                "Google Ads",
                "SEO",
              ].map((item, index) => (
                <li key={index}>
                  <a href="#" className="hover-effect">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/** Industry Section */}
          <div className="footer-section">
            <h3 className="text-lg font-semibold mb-4 border-b border-muted pb-2">
              Industry
            </h3>
            <ul className="space-y-2">
              {[
                "AnyCost",
                "Amazon Web Services",
                "Google Cloud Platform",
                "Microsoft Azure",
                "Snowflake",
                "Datadog",
              ].map((item, index) => (
                <li key={index}>
                  <a href="#" className="hover-effect">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/** Resources Section */}
          <div className="footer-section">
            <h3 className="text-lg font-semibold mb-4 border-b border-muted pb-2">
              Resources
            </h3>
            <ul className="space-y-2">
              {[
                "Blog",
                "Newsroom",
                "Podcast",
                "Events",
                "Customer Stories",
                "Free Cost Assessment",
              ].map((item, index) => (
                <li key={index}>
                  <a href="#" className="hover-effect">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/** Company Section */}
          <div className="footer-section">
            <h3 className="text-lg font-semibold mb-4 border-b border-muted pb-2">
              Company
            </h3>
            <ul className="space-y-2">
              {["About", "Customers", "Partners", "Careers", "Contact"].map(
                (item, index) => (
                  <li key={index}>
                    <a href="#" className="hover-effect">
                      {item}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </footer>

      {/* Social links and copyright */}
      <footer className="bg-[#234190] text-white px-[8rem] py-4">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
          {/** Social Icons */}
          <div className="flex space-x-0  mb-4 md:mb-0">
            <a href="https://www.linkedin.com" aria-label="LinkedIn">
              <img
                src={linkedinIcon }
                 width='50px'
                
                alt="LinkedIn"
                className="bg-primary p-2 rounded"
              />
            </a>
            <a href="https://www.twitter.com" aria-label="Twitter">
              <img
                src={twitterIcon}
                width='50px'
                alt="Twitter"
                className="bg-primary p-2 rounded"
              />
            </a>
            <a href="https://www.facebook.com" aria-label="Facebook">
              <img
                src={facebookIcon}
                 width='50px'
                alt="Facebook"
                className="bg-primary p-2 rounded"
              />
            </a>
            <a href="https://www.instagram.com" aria-label="Instagram">
              <img
                src={instaIcon }
                 width='50px'
                sizes="10px"
                alt="Instagram"
                className="bg-primary p-2 rounded"
              />
            </a>
          </div>

          {/** Footer Links */}
          <div className="flex space-x-4 text-muted-foreground">
            {[
              { text: "Terms Of Use", url: "https://example.com" },
              { text: "Privacy Policy", url: "https://example.com" },
              { text: "Security", url: "https://example.com" },
            ].map((item, index) => (
              <a key={index} href={item.url} className="hover-effect">
                {item.text}
              </a>
            ))}
          </div>

          {/** Copyright */}
          <div className="text-muted-foreground mt-4 md:mt-0">
            Copyright © 2024
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
