import React from 'react'
import Navbar from '../component/Navbar'
import Router from '../Router'
import Footer from '../component/Footer'

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Router />
      <Footer />
    </div>
  )
}

export default Layout