import React from 'react';
import { FaArrowRight } from 'react-icons/fa'; // Import the arrow icon from react-icons
import './ServiceCard.css';
import seo from '../assets/Assets-Vittipanya/SEO.png';
import googleAds from '../assets/Assets-Vittipanya/Google Ads.png';
import logoDesign from '../assets/Assets-Vittipanya/LOGO (1).png';
import contentMarketing from '../assets/Assets-Vittipanya/Meta Ads.png';
import socialMedia from '../assets/Assets-Vittipanya/Social Media Marketing.png';
import websiteDesign from '../assets/Assets-Vittipanya/Website Design & Development.png';

const services = [
  {
    id: 1,
    title: "SEO SERVICES",
    img: seo,
  },
  {
    id: 2,
    title: "WEBSITE DESIGN",
    img: websiteDesign,
  },
  {
    id: 3,
    title: "CONTENT MARKETING",
    img: contentMarketing,
  },
  {
    id: 4,
    title: "LOGO DESIGN",
    img: logoDesign,
  },
  {
    id: 5,
    title: "SOCIAL MEDIA MARKETING SERVICES",
    img: socialMedia,
  },
  {
    id: 6,
    title: "GOOGLE ADS",
    img: googleAds,
  },
];

const ServiceCard = () => {
  return (
    <div className="w-full p-4 lg:p-10 mt-7">
      {/* Heading */}
      <div className="text-center mb-5">
        <h1 className="text-2xl md:text-3xl font-bold text-[#234190]">We Offer a Wide Range of Digital Marketing Services</h1>
        <p className='text-center text-sm md:text-md lg:text-lg font-normal lg:w-[80%] lg:mx-auto font-medium mt-4'>
          Unlock your business's full potential with our comprehensive digital marketing services. From strategic online campaigns to cutting-edge SEO, we offer tailored solutions to enhance your brand visibility, engage your audience, and drive sustainable growth in the digital age.
        </p>
      </div>

      {/* Service Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-4 lg:p-12">
        {services.map(service => (
          <div
            key={service.id}
            className="bg-card text-card-foreground dark:bg-card dark:text-card-foreground rounded-xl shadow-xl overflow-hidden transition-transform duration-300 hover:shadow-3xl hover:scale-105"
          >
            <div className="flex justify-center items-center h-48 bg-gray-100 dark:bg-gray-700">
              <img src={service.img} alt={service.title} className="object-cover w-full h-full" />
            </div>
            <div className="p-4 flex flex-col justify-between h-32">
              <h2 className="text-xl mt-5 font-semibold text-center">{service.title}</h2>
              <a href="#" className="text-primary hover:underline flex items-center justify-center mt-4">
                Know More
                <FaArrowRight className="w-4 h-4 ml-1 transform rotate-[320deg]" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCard;
