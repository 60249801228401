import React from 'react';
import './ClientsSection.css';

const clients = [
  { id: 1, name: 'Kashmir Tourmate', imgUrl: 'https://ad2brand.com/wp-content/uploads/2023/07/tali-2-340x240-1.jpeg' },
  { id: 2, name: 'Raj Girl’s Hostel', imgUrl: 'https://ad2brand.com/wp-content/uploads/2023/07/logo-1-340x240-1.jpeg' },
  { id: 3, name: 'Chadrabeni Shiksha Niketan Trust', imgUrl: 'https://ad2brand.com/wp-content/uploads/2023/07/Sesame-1-340x240-1.jpeg' },
  { id: 4, name: 'JCRL Diagnostic', imgUrl: 'https://ad2brand.com/wp-content/uploads/2023/07/Ricoira-340x240-1.jpeg' },
  { id: 5, name: 'Galaxy Diagnostic', imgUrl: 'https://ad2brand.com/wp-content/uploads/2023/07/Bhandari-340x240-1.jpeg' },
];

const ClientsSection = () => {
  return (
    <div className='clients-section'>
      <div className='w-95 h-auto'>
      <h1 className="text-3xl text-center font-bold text-[#234190]">Our Clients</h1>
      
        <p className='text-center text-md font-normal lg:w-[80%] lg:mx-auto font-medium mt-[1rem]'>We are proud to have worked with a diverse range of clients who have trusted us to help them achieve their goals. Here are some of our esteemed clients:</p>
        <div className='clients-wrapper'>
          {clients.map(client => (
            <div key={client.id} className="client-card">
              <div className="client-card-inner" style={{ backgroundImage: `url(${client.imgUrl})` }}>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
